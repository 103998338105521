(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

// import './autoreg.js';
$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), "_self");
});
$(document).ready(function () {
    $('article').prependTo($('.main-text'));
    $('article h1').appendTo($('.for-h'));

    //menu toggle
    $('#menu-toggle-but').click(function () {
        $(this).toggleClass('open');
        $(".header-top-nav").slideToggle('fast');
    });

    // $('.slider').slick({
    //     autoplay: true,
    // });

    var pageId = $('.page-id_hidden').data('id');
    var sliderItem = $('.slider-for__slide');

    for (var i = 0; i < sliderItem.length; i++) {
        if (sliderItem[i].dataset.id == pageId) {
            var findId = i;
        }
    }

    // if ($(".slider").length) {
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        asNavFor: '.slider-nav',
        infinite: false,
        initialSlide: findId
    });

    $('.slider-nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        vertical: true,
        verticalSwiping: false,
        focusOnSelect: true,
        infinite: false,
        adaptiveHeight: true,
        initialSlide: findId
        //   responsive: [
        //     {
        //       breakpoint: 640,
        //       settings: {
        //         slidesToShow: 1,
        //       }
        //     }
        //   ]
    });
    //   }


    $('.slide-next').on('click', function () {
        $('.slider').slick('slickNext');
    });
    $('.slide-prev').on('click', function () {
        $('.slider').slick('slickPrev');
    });

    if ($('.sub-menu')) {
        $(this).parent().addClass('qwe');
    }

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 550) {
            $('.to-top-btn').css('display', 'block');
        } else {
            $('.to-top-btn').css('display', 'none');
        }
    });

    $('.to-top-btn').click(function () {
        $('html, body').animate({
            scrollTop: $('#header').offset().top
        }, 500);
    });

    if (parseInt($(window).width()) <= 550) {
        var headerHeight = $('#header').height();
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > headerHeight - 100) {
                $('.main-banner-section').css('position', 'fixed');
            } else {
                $('.main-banner-section').css('position', 'relative');
            }
        });
    }

    $('.close-btn').click(function () {
        $('.main-banner-section').remove();
    });

    var headerSlideNav = $('.slider-nav__slide');

    headerSlideNav.each(function (i, el) {
        el.text = el.text.split('|')[0];
    });

    // Banner after 5th item

    var fifthItem = $('.latest-post-item')[4];
    var banForItem = $('#ban-for-itemlist')[0];

    if (fifthItem && banForItem) {
        fifthItem.after(banForItem);
    }
});

},{}]},{},[1]);
